<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none d-md-inline-flex d-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!--/ Nav Menu Toggler -->

    <!-- Left Logo -->
    <b-navbar-nav class="bookmark-wrapper align-items-center d-block d-sm-block d-md-none d-lg-none d-xl-none">
      <b-row>
        <b-col
          cols="2"
          class="pl-2 pr-1"
        >
          <!-- Logo -->
          <ul class="nav navbar-nav flex-row">
            <!-- Logo & Text -->
            <li class="nav-item mr-auto">
              <b-link :to="checkPermission(['VIEW_DASHBOARD']) ? { name: 'dashboard-main' } : { name:'not-authorized' }">
                <div class="row">
                  <!-- <neutral-horizontal-ostrail-logo v-if="checkPermission(['ALLOW_ALL'])" /> -->
                  <b-avatar
                    v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
                    class="mr-1 i-avatar-cl i-mg-ostl-lg"
                    rounded="sm"
                    size="39px"
                    src="@/assets/images/ostrail/logo.png"
                  />
                  <b-avatar
                    v-else
                    class="i-avatar-cl i-mg-ostl-lg"
                    rounded="sm"
                    size="39px"
                    :src="clubInfo.club_image_file_hash == null ? null : path + clubInfo.club_image_media_file.url_relative"
                  />
                </div>
              </b-link>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-navbar-nav>
    <!--/ Left Col -->

    <!-- Left Col -->
    <b-navbar-nav
      v-if="optionsMenu.length"
      class="bookmark-wrapper align-items-center flex-grow-1 d-lg-block"
    >
      <b-row>
        <b-col
          cols="12"
          class="pl-2"
        >
          <div>
            <nav-menu
              :options-menu="optionsMenu"
              :check-permission="checkPermission"
            />
          </div>
        </b-col>
      </b-row>
    </b-navbar-nav>
    <!--/ Left Col -->

    <!--/ Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--<search />-->
      <div v-if="!checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
        <club-filter />
      </div>
      <locale :is-nav="true"/>
      <notification-dropdown />
      <message-dropdown />
      <!-- <span
        @click="shallShowMessageComposeModal = true"
      >
        msg
        <email-compose v-model="shallShowMessageComposeModal" />
      </span> -->
      <user-dropdown />
    </b-navbar-nav>
    <!--/ Right Col -->
  </div>
</template>

<script>
import checkPermission from '@/auth/permissions'

import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'

import NavMenu from '@/views/partials/NavMenu.vue'
import Locale from './components/Locale.vue'
import ClubFilter from './components/ClubFilter.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
// import Search from './components/SearchBar.vue'
import MessageDropdown from './components/MessageDropdown.vue'

export default {
  components: {
    BLink,
    // BRow,
    // BCol,
    // Navbar Components
    BNavbarNav,
    Locale,
    ClubFilter,
    NotificationDropdown,
    UserDropdown,
    NavMenu,
    // Search,
    MessageDropdown,
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      shallShowMessageComposeModal: false,
      optionsMenu: [],
      clubList: [
        { route: { name: 'clubs' } },
        { route: { name: 'create-club' } },
        { route: { name: 'edit-club' } },
        { route: { name: 'show-club' } },
        { route: { name: 'admin-create-club' } },
        { route: { name: 'corporate' } },
        { route: { name: 'society' } },
        { route: { name: 'representative' } },
        { route: { name: 'power-of-attorney' } },
        { route: { name: 'assemblies' } },
        { route: { name: 'intellectual-property' } },
        { route: { name: 'pi-agreements' } },
        { route: { name: 'pi-trademarks' } },
        { route: { name: 'pi-literary-or-artistic-works' } },
      ],
      memberList: [
        { route: { name: 'member' } },
        { route: { name: 'create-member' } },
        { route: { name: 'details-member' } },
        { route: { name: 'edit-member' } },
      ],
      roleList: [
        { route: { name: 'roles' } },
      ],
      userList: [
        { route: { name: 'users' } },
      ],
      permissionList: [
        { route: { name: 'permissions' } },
      ],
      jobPositionList: [
        { route: { name: 'job-position' } },
      ],
      userPermissionList: [
        { route: { name: 'users-permissions' } },
      ],
      teamList: [
        { route: { name: 'team' } },
      ],
      newList: [
        { route: { name: 'news' } },
        { route: { name: 'new-edit' } },
        { route: { name: 'new-add' } },
        { route: { name: 'new-details' } },
        { route: { name: 'new-posts' } },
      ],
      todoList: [
        { route: { name: 'todo' } },
        { route: { name: 'todo-filter' } },
        { route: { name: 'todo-tag' } },
      ],
      logList: [
        { route: { name: 'logs-info' } },
      ],
    }
  },
  computed: {
    ...mapGetters({
      clubInfo: 'clubInfo',
      role: 'role',
      path: 'path',
      hasClub: 'hasClub',
    }),
  },

  watch: {
    '$route.name': function () {
      this.assignOptionsMenu()
    },
  },

  mounted() {
    this.loadDataNotifications()
  },

  methods: {
    checkPermission,
    ...mapActions({
      fetchNotifications: 'notificationStore/fetchData',
      fetchCounterNotifications: 'notificationStore/fetchCounterNotifications',
    }),
    async loadDataNotifications() {
      this.assignOptionsMenu()
      await this.fetchNotifications()
      await this.fetchCounterNotifications()
    },
    assignOptionsMenu() {
      if (
        this.findInArray(this.clubList)
        || this.findInArray(this.memberList)
        || this.findInArray(this.teamList)
      ) {
        this.optionsMenu = [
          {
            id: 1,
            name: 'routes.documentalCollectionRoute.club',
            icon: 'icon-0-icons-dark-football',
            route: { name: 'clubs' },
            status: this.findInArray(this.clubList),
            disabled: false,
            key: 'SPCLA',
          },
          {
            id: 2,
            name: 'routes.documentalCollectionRoute.members',
            icon: 'icon-0-icons-dark-contacts',
            route: { name: 'member' },
            status: this.findInArray(this.memberList),
            disabled: false,
            key: 'MBERO',
          },
          {
            id: 3,
            name: 'routes.documentalCollectionRoute.teams',
            icon: 'icon-0-icons-dark-aim',
            route: { name: 'team' },
            status: this.findInArray(this.teamList),
            disabled: false,
            key: 'TAMSE',
          },
        ]
      } else if (
        this.findInArray(this.roleList)
        || this.findInArray(this.userList)
        || this.findInArray(this.jobPositionList)
        || this.findInArray(this.newList)
        || this.findInArray(this.todoList)
        || this.findInArray(this.logList)
      ) {
        this.optionsMenu = ((this.hasClub && this.role.is_admin_club) || this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))
          ? [
            {
              id: 1,
              name: 'routes.managementRoute.roles',
              icon: 'icon-0-icons-dark-folded',
              route: { name: 'roles' },
              status: this.findInArray(this.roleList),
              disabled: false,
              key: 'ROLLI',
            },
            {
              id: 2,
              name: 'routes.managementRoute.users',
              icon: 'icon-0-icons-dark-avatar',
              route: { name: 'users' },
              status: this.findInArray(this.userList),
              disabled: false,
              key: 'USESO',
            },
            {
              id: 3,
              name: 'routes.managementRoute.jobPositions',
              icon: 'icon-0-icons-dark-badge',
              route: { name: 'job-position' },
              status: this.findInArray(this.jobPositionList),
              disabled: false,
              key: 'JBPTE',
            },
            {
              id: 4,
              name: 'routes.managementRoute.news',
              icon: 'icon-0-icons-dark-newsfeed',
              route: { name: 'news' },
              status: this.findInArray(this.newList),
              disabled: false,
              key: 'NESSA',
            },
            {
              id: 5,
              name: 'routes.managementRoute.todo',
              icon: 'icon-0-icons-dark-pin',
              route: { name: 'todo' },
              status: this.findInArray(this.todoList),
              disabled: false,
              key: 'PEGSO',
            },
            {
              id: 6,
              name: 'routes.managementRoute.logs',
              icon: 'icon-0-icons-dark-list-text',
              route: { name: 'logs-info' },
              status: this.findInArray(this.logList),
              disabled: false,
              key: 'LGINN',
            },
          ]
          : [
            {
              id: 3,
              name: 'routes.managementRoute.jobPositions',
              icon: 'icon-0-icons-dark-badge',
              route: { name: 'job-position' },
              status: this.findInArray(this.jobPositionList),
              disabled: false,
              key: 'JBPTE',
            },
            {
              id: 4,
              name: 'routes.managementRoute.news',
              icon: 'icon-0-icons-dark-newsfeed',
              route: { name: 'news' },
              status: this.findInArray(this.newList),
              disabled: false,
              key: 'NESSA',
            },
            {
              id: 5,
              name: 'routes.managementRoute.todo',
              icon: 'icon-0-icons-dark-pin',
              route: { name: 'todo' },
              status: this.findInArray(this.todoList),
              disabled: false,
              key: 'PEGSO',
            },
            {
              id: 6,
              name: 'routes.managementRoute.logs',
              icon: 'icon-0-icons-dark-list-text',
              route: { name: 'logs-info' },
              status: this.findInArray(this.logList),
              disabled: false,
              key: 'LGINN',
            },
          ]
      } else if (this.findInArray(this.permissionList)) {
        this.optionsMenu = ((this.hasClub && this.role.is_admin_club) || this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))
          ? [
            {
              id: 1,
              name: 'routes.managementRoute.roles',
              icon: 'icon-0-icons-dark-folded',
              route: { name: 'roles' },
              disabled: false,
              key: 'ROLLI',
            },
            {
              id: 2,
              name: 'routes.managementRoute.permissions',
              icon: 'icon-0-icons-dark-lock-closed',
              route: { name: 'permissions' },
              status: this.findInArray(this.permissionList),
              disabled: true,
              key: 'PRSSO',
            },
          ]
          : []
      } else if (this.findInArray(this.userPermissionList)) {
        this.optionsMenu = ((this.hasClub && this.role.is_admin_club) || this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL']))
          ? [
            {
              id: 1,
              name: 'routes.managementRoute.users',
              icon: 'icon-0-icons-dark-avatar',
              route: { name: 'users' },
              disabled: false,
              key: 'USESO',
            },
            {
              id: 2,
              name: 'routes.managementRoute.permissions',
              icon: 'icon-0-icons-dark-lock-closed',
              status: this.findInArray(this.userPermissionList),
              route: { name: 'users-permissions' },
              disabled: true,
              key: 'PRSSO',
            },
          ]
          : []
      } else {
        this.optionsMenu = []
      }
    },

    findInArray(list) {
      return (list.findIndex(item => item.route.name === this.$route.name) !== -1)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.ellipsis {
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  width: 145px;
}

.i-text-cl-name-md {
  margin-left: 6px;
  margin-top: 23px;
  font-size: 15px;
}

.i-text-cl-name-lg {
  margin-left: 6px;
  margin-top: 17px;
  font-size: 15px;
}

.i-avatar-cl {
  background-color: transparent;
  padding: 1px;
  margin-left: 14px;
}

.i-mg-ostl-lg {
  margin-top: 5px;
}
</style>
